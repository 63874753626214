import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import * as fromPrivate from '../private';
import { PrivateService } from './private.service';

@Injectable()
export class PrivateEffects {
	constructor(private readonly actions$: Actions, private readonly privateService: PrivateService) {}

	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ROOT_EFFECTS_INIT),
			switchMap(() => [fromPrivate.loadHashes()])
		)
	);

	loadHashes$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPrivate.loadHashes.type),
			switchMap(() => this.privateService.getEntryHashes()),
			map((entryHashes) => fromPrivate.loadHashesSuccess({ entryHashes }))
		)
	);
}

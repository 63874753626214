import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../data/general.data';
import * as fromPrivate from 'src/app/store/private';
import { delay, map, first } from 'rxjs/operators';
import { IEntryHashMap } from '../store/private/private.model';

@Injectable()
export class PrivatePagesGuardService {
	constructor(private readonly router: Router, private readonly store: Store<IAppState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const entryHashes: Observable<any> = this.store.select(fromPrivate.selectEntryHashesMap);
    const enteredKey = localStorage.getItem('enteredKey');

    return entryHashes.pipe(
      first(entryHashMap => !!entryHashMap.isLoaded),
      map((entryHashMap: IEntryHashMap) => {
        const requestedPath = route.url[0].path;
        const publicEntry = Object.values(entryHashMap).find((entry) => entry.redirect === requestedPath && entry.public);

        if (publicEntry) {
          return true;
        }

        if (!enteredKey) {
          this.router.navigate(['nice']);
          return false;
        }

        const hashedKeyExists = enteredKey in entryHashMap;

        if (hashedKeyExists) {
          if (entryHashMap[enteredKey].redirect === requestedPath) {
            return true;
          } else {
            this.router.navigate(['nice']);
            return false;
          }
        } else {
          this.router.navigate(['nice']);
          return false;
        }
      })
    );
  }
}
